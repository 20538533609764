<script setup lang="ts">
import { cn } from '@/lib/utils';
import { ChevronDown } from 'lucide-vue-next';
import { SelectIcon, SelectTrigger, type SelectTriggerProps } from 'radix-vue';

const props = withDefaults(
  defineProps<SelectTriggerProps & { class?: string; invalid?: boolean }>(),
  {
    class: '',
    invalid: false,
  }
);
</script>

<template>
  <SelectTrigger
    v-bind="props"
    :class="[
      cn(
        'flex h-[3.1rem]  w-full items-center justify-between  border-b border-b-iq-grey-dark border-input !bg-iq-white px-3 text-regular-20 disabled:cursor-not-allowed disabled:opacity-50 shadow-iq-inset placeholder:!text-iq-grey-light placeholder:text-regular-20 !text-iq-grey-dark',
        props.class
      ),
      props.invalid
        ? '!ring-destructive ring-2 placeholder:!text-destructive'
        : '',
    ]"
  >
    <slot />
    <SelectIcon as-child>
      <ChevronDown class="w-4 h-4 opacity-50 !text-iq-white ml-2" />
    </SelectIcon>
  </SelectTrigger>
</template>
